
.titlePreRegister h5{
    color: rgb(223, 148, 27);
    font-weight: bold !important;
}

.titlePreRegister p{
    color: gray;
    font-size: 2vh;
}

.bntGuardar{
    margin: auto;
    text-align: center;
}

.btnGuardarPreRegister{
  background: #ed8f1c!important;
  border-color: #f29017;
  // font-weight: bold !important;
  width: 120px !important;
  // height: 50px!important;
  color: rgb(251, 250, 248) !important;
  border-radius: 50px !important;
  margin-top: 2vh;
}
  
.nacionalidad{
  background-color: black;
}