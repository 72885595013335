.inputEstablecimiento {
    display: block !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #ffffff !important;
    line-height: 1.3 !important;
    padding: .4em 1.4em .3em .8em !important;
    width: 80% !important;
    height: 50px;
    max-width: 80% !important;
    margin: auto;
    border: 1px solid rgb(248, 247, 247);
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, .03) !important;
    border-radius: 10px !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    background-color: rgb(202, 195, 195) !important;
    background-image: url('../../../public/imageness/home-orange.png'),
        linear-gradient(to bottom, #f9f9f9 0%, #f7f7f7 100%) !important;
    background-repeat: no-repeat, repeat !important;
    background-position: right 50px top 50%, 0 0 !important;
    background-size: 25px auto, 100% !important;
}

.nombreListaEstablecimiento{
  color: rgb(68, 67, 67) !important;
  font-weight: bold !important;
  margin: auto !important;
  margin-left: 80px !important;
  font-size: 16px !important;
}

.ListaEstablecimiento{
    margin-left: 80px !important;
   color: rgb(229, 150, 23) !important;
}

.inputBuscadorAsignacion{
    margin-left: 70%;
    width: 300px;
    height: 30px;
    border-radius: 5px;
    border-color: rgb(255, 254, 254);
    text-align: center;
    color: gray;
    margin-top: 5px;
    background-image: url('../../../public/imageness/Search.svg'),
          linear-gradient(to bottom, #f9f9f9 0%, #f7f7f7 100%) !important;
          background-repeat: no-repeat, repeat !important;
          background-position: left 10px top 50%, 0 0 !important;
          background-size: 20px auto, 100% !important;
  
  }

  .title{
    font-size: 2vh !important;
}

