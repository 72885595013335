@import "../../dx-styles.scss";
@import "../../themes/generated/variables.additional.scss";

.side-navigation-menu {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;
  width: 200px !important;
  overflow-x: none;

  .menu-container {
    min-height: 100%;
    // display: flex;
    // flex: 1;

    .dx-treeview {
      // ## Long text positioning
      white-space: nowrap;
      margin-top: 1vh;
      height: 55%;
      word-break: break-word !important;
      // ##

      // ## Icon width customization
      .dx-treeview-item {
        font-size: 1.3vh;
        padding-left: 2vh;
        padding-right: 0;

        .dx-icon {
          // width: $side-panel-min-width !important;
          margin: 0 2vh 0 0 !important;
        }
      }
      // ##

      // ## Arrow customization
      .dx-treeview-node {
        padding: 0 2vh !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: auto;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }
      // ##

      // ## Item levels customization
      .dx-treeview-node {
        &[aria-level='1'] {
          font-weight: bold;
          color: rgb(143, 136, 136);
          // border-bottom: 1px solid $base-border-color;
        }

        &[aria-level='2'] .dx-treeview-item-content {
          font-weight: normal;
          padding: 0 $side-panel-min-width;
        }
      }
      // ##
    }

    // ## Selected & Focuced items customization
    .dx-treeview {
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused)> .dx-treeview-item {
            background-color: rgb(255, 255, 255);
            color: rgb(242, 167, 28);
          }

          &.dx-state-selected > .dx-treeview-item {
            color: rgb(242, 167, 28);

          }

          &:not(.dx-state-focused)>.dx-treeview-item.dx-state-hover  {
            background-color: rgb(233, 233, 233);
            color: rgb(143, 136, 136);
            border-radius: 15vh;
          }
        }
      }
    }

    .dx-theme-generic .dx-treeview {
      .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
        color: inherit;
      }
    }
    // ##
  }
}
.side-navigation-menu::-webkit-scrollbar {
width: 8px;
  /* Tamaño del scroll en vertical */
  height: 8px;
  /* Tamaño del scroll en horizontal */
  display: none;
}

.optionsMenuContainer{
  // background-color: aqua;
  width: 100%;
  height: 20%;
  margin-top: 2vh;
  border-top: 0.15vh solid rgba(231, 231, 231, 0.356);
  padding: 0 2vh;
}

.optionsMenu{
  width: 100%;
  height: 20%;
  // background-color: aqua;
  margin-top: 1vh;
  font-size: 1.5vh;
  font-weight: 500;
  padding: 0 3vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: orange;
  cursor: pointer;

  svg {
    margin-right: 3vh;
    font-size: 2vh !important;
  }

  p{
    margin: 0;
    padding: 0;
  }
}

.logOut{
  color: red;
}

.dx-drawer-overlap.pre-init-blink-fix {
  @import "../../utils/patches.scss";
  @include menu-pre-init-patch;
}

.dx-swatch-additional .dx-treeview-item .dx-treeview-item-content > .dx-icon{
  font-size: 3vh !important;
}