.TituloordenesCompra{
    color:rgb(162, 156, 156);
    font-weight: 500 !important;
    font-size: 40px !important;
    margin-top: -10px !important;
    
   }
   
   .btnGenerarordenesCompra{
       background: rgb(242, 140, 14) !important;
       color: white !important;
       width: 100%;
       border-radius: 10px !important;
       height: 50px !important;
       font-size: 16px !important;
       text-align: center;
       font-weight: 500 !important;
   }
   .dx-fieldset{
       margin: auto !important;
       width: 70%;
   }
   .dx{
       width: 100%;
       margin-bottom: 20px;
   }
   
   .BTN{
       width: 20%;
       margin: auto !important;
   }
   
   .SelectBoxC {
       display: block !important;
       font-size: 12px !important;
       font-weight: 400 !important;
       color: #ffffff !important;
       line-height: 1.3 !important;
       padding: .4em 1.4em .3em .8em !important;
       width: 80% !important;
       height: 50px;
       max-width: 100% !important;
       margin: 20px auto !important;
       border: 1px solid rgb(248, 247, 247);
       box-shadow: 0 1px 0 1px rgba(0, 0, 0, .03) !important;
       border-radius: 10px !important;
       -moz-appearance: none !important;
       -webkit-appearance: none !important;
       appearance: none !important;
       background-color: rgb(202, 195, 195) !important;
   }
   
   .SelectBoxF{
           display: block !important; 
           font-size: 12px !important;
           font-weight: 400 !important;
           color: #ffffff !important;
           line-height: 1.3 !important;
           padding: .4em 1.4em .3em .8em !important;
           width: 80% !important;
           height: 50px;
           max-width: 100% !important;
           margin: 20px auto !important;
           border: 1px solid rgb(248, 247, 247);
           box-shadow: 0 1px 0 1px rgba(0, 0, 0, .03) !important;
           border-radius: 10px !important;
           -moz-appearance: none !important;
           -webkit-appearance: none !important;
           appearance: none !important;
           background-color: rgb(202, 195, 195) !important;
       }
   
       .SelectBoxR{
           display: block !important;
           font-size: 12px !important;
           font-weight: 400 !important;
           color: #ffffff !important;
           line-height: 1.3 !important;
           padding: .4em 1.4em .3em .8em !important;
           width: 80% !important;
           height: 50px;
           max-width: 100% !important;
           margin: 20px auto !important;
           border: 1px solid rgb(248, 247, 247);
           box-shadow: 0 1px 0 1px rgba(0, 0, 0, .03) !important;
           border-radius: 10px !important;
           -moz-appearance: none !important;
           -webkit-appearance: none !important;
           appearance: none !important;
           background-color: rgb(202, 195, 195) !important;
       }
   
       .SelectBoxR {
           display: block !important;
           font-size: 12px !important;
           font-weight: 400 !important;
           color: #ffffff !important;
           line-height: 1.3 !important;
           padding: .4em 1.4em .3em .8em !important;
           width: 80% !important;
           height: 50px;
           max-width: 100% !important;
           margin: 20px auto !important;
           border: 1px solid rgb(248, 247, 247);
           box-shadow: 0 1px 0 1px rgba(0, 0, 0, .03) !important;
           border-radius: 10px !important;
           -moz-appearance: none !important;
           -webkit-appearance: none !important;
           appearance: none !important;
           background-color: rgb(202, 195, 195) !important;
       }
   
   
       .SelectBoxDate {
           display: block !important;
           font-size: 12px !important;
           font-weight: 400 !important;
           color: #ffffff !important;
           line-height: 1.3 !important;
           padding: .9em 1.4em .3em .9em !important;
           width: 80% !important;
           height: 50px;
           max-width: 100% !important;
           margin: 20px auto !important;
           border: 1px solid rgb(248, 247, 247);
           box-shadow: 0 1px 0 1px rgba(0, 0, 0, .03) !important;
           border-radius: 10px !important;
           -moz-appearance: none !important;
           -webkit-appearance: none !important;
           appearance: none !important;
           background-color: rgb(202, 195, 195) !important;
       }
       
   