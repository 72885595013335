.title{
    font-size: 2vh !important;
}

.userAssignmentTitle{
    margin-bottom: 1vh !important;
    line-height: 0;
}

.textButton{
    font-size: 1.5vh;
    margin-top: 1.5vh !important;
}

.texto{
    font-size: 1.7vh !important;
}