.titleEstablecimiento{
    color: rgb(223, 148, 27);
    font-weight: bold !important;
}

.containerButtons{
    height: 10vh;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}