  .left .list .dx-list-group-header {
    color: #f05b41;
    font-weight: normal;
    font-size: 18px;
  }
  
  .left .list .hotel {
    float: left;
  }
  
  .left .list .hotel .name {
    font-weight: bold;
    font-size: 18px;
  }

  .left .list .caption {
    font-size: 13px;
    line-height: 12px;
    padding-left: 2px;
    opacity: 0.7;
    margin-top: 6px;
  }

  .left .list .address{
    font-size: 17px;
    margin-top: 5px;
  }

  .list-forms{
    .dx-item.dx-list-item{
      border: solid 1px #f49f3c;
      border-radius: 14px;
      width: auto;
      margin-right: 20px;
      background-color: #f8fafc !important;
      margin-bottom: 2%;
      padding-right: 10px;
    }

    .form-item{
      .name h6{
        font-size: 16px;
        margin-bottom: 12px;
      }
      p{
        margin-bottom: 0px;
        font-weight: 500;
        margin-right: 30px;
      }
      .cliente, .version{
        margin-top: 10px;
        font-size: 14px;
      }
    } 

    .dx-scrollview-content{
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 100%;
    }
  }

  .box-item-repeater{
    margin-top: 12px;
    margin-bottom: 20px;
    // border: 1px solid #f49f3c;
    -webkit-box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.30);
    -moz-box-shadow: 0px 2px 10px 1px rgba(0,0,0,0.30);
    // box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.30);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 15px;
    padding: 24px;
  }

  .container-box-repeater{
    padding: 6px;
  }

  .ml-2{
    margin-left: 1em;
  }